.introduce {
   padding: em(80) em(11);
   @include small-tablet {
      padding-top: to-rem(39);
      padding-right: to-rem(13);
      padding-left: to-rem(13);
   }
   display: flex;
   flex-direction: column;
   &__body {
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      gap: em(20);
      width: 100%;
      @include small-tablet {
         flex-direction: column;
         gap: to-rem(18);
      }
   }
   &__info {
      padding-top: em(13);
      width: 100%;
      @include small-tablet {
         padding-top: 0;
      }
   }
   &__title {
      color: #ffffff;
      font-size: em(36);
      @function em($one, $two: 36) {
         @return prc($one, $two, 1) + 0em;
      }
      line-height: 1.45;
      font-weight: 900;
      letter-spacing: em(2.5);
      text-transform: uppercase;
      max-width: to-rem(651);
      @include small-tablet {
         font-size: to-rem(20);
         max-width: calc(100% - em(140));
      }
      @include media-query(576, min) {
         br {
            display: none;
         }
      }
      @include mobile {
         max-width: none;
      }
      strong {
         display: inline;
         font-weight: 900;
         color: $theme-1;
      }
   }
   &__row {
      display: flex;
      align-items: center;
      gap: em(20);
      justify-content: space-between;
      margin-top: em(-20);
      margin-bottom: em(15);
      max-width: to-rem(770);
      @include small-tablet {
         flex-direction: column-reverse;
         align-items: flex-end;
         margin-top: to-rem(-60);
         gap: 0;
         margin-bottom: to-rem(26);
      }
   }
   &__list {
      display: flex;
      flex-wrap: wrap;
      gap: em(4) em(14);
      max-width: to-rem(452);
      @include small-tablet-min {
         padding-top: em(25);
      }
      li {
         position: relative;
         font-size: to-rem(18);
         @function em($one, $two: 18) {
            @return prc($one, $two, 1) + 0em;
         }
         padding-left: em(24);
         &:first-child {
            min-width: em(119.27);
         }
         line-height: 1.55;
         @include small-tablet {
            font-size: to-rem(16);
         }
         &:before {
            content: "";
            position: absolute;
            left: 0;
            font-size: em(10);
            @function em($one, $two: 10) {
               @return prc($one, $two, 1) + 0em;
            }
            top: 0.9em;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            background: #fbb03c;
         }
      }
   }
   &__sub-title {
      position: relative;
      font-size: to-rem(18);
      @function em($one, $two: 18) {
         @return prc($one, $two, 1) + 0em;
      }
      padding-left: em(19);
      line-height: 1.55;
      font-weight: 600;
      letter-spacing: em(0.5);
      max-width: to-rem(633);
      margin-bottom: em(48);
      @include small-tablet {
         font-size: to-rem(14);
         margin-bottom: to-rem(32.5);
      }
      &:before {
         content: "";
         position: absolute;
         width: to-rem(2);
         height: 73%;
         border-radius: to-rem(2);
         top: 13.5%;
         left: 0;
         background: $theme-1;
      }
   }
   &__bottom-row {
      display: flex;
      gap: em(12);
      flex-wrap: wrap;
      align-items: center;
      @include mobile {
         justify-content: center;
         gap: to-rem(19);
      }
   }
   &__btn {
      margin-right: em(12);
      min-width: em(260);
      @include mobile {
         min-width: 0;
         order: 2;
         margin-right: 0;
         margin-bottom: to-rem(3);
      }
   }
   &__object {
      display: inline-flex;
      gap: em(12);
      align-items: center;
      @include small-tablet {
         order: 3;
      }
      img,
      svg {
         font-size: em(50);
         @function em($one, $two: 50) {
            @return prc($one, $two, 1) + 0em;
         }
         width: 1em;
         height: 1em;
         @include small-tablet {
            font-size: to-rem(36);
         }
      }
      span {
         font-size: to-rem(14);
         @function em($one, $two: 14) {
            @return prc($one, $two, 1) + 0em;
         }
         line-height: 1.1;
         font-weight: 500;
         letter-spacing: em(0.5);
         max-width: to-rem(148);
         @include small-tablet {
            font-size: to-rem(12);
            max-width: to-rem(170);
         }
      }
   }
   &__time {
      position: relative;
      padding: em(5) 0;
      display: inline-flex;
      align-items: center;
      @include small-tablet {
         order: 1;
      }
      &:before {
         content: "";
         position: absolute;
         width: 90%;
         left: 5%;
         height: to-rem(1);
         bottom: 0;
         background: #fff;
         @include small-tablet {
            width: 80%;
         }
      }
      img,
      svg {
         font-size: em(38);
         @function em($one, $two: 38) {
            @return prc($one, $two, 1) + 0em;
         }
         width: 1em;
         height: 1m;
         @include small-tablet {
            font-size: to-rem(38);
         }
      }
      span {
         font-size: to-rem(13);
         @function em($one, $two: 13) {
            @return prc($one, $two, 1) + 0em;
         }
         font-family: "Averta", Arial, sans-serif;
         line-height: 1.4;
         font-weight: 480;
         letter-spacing: em(1);
         max-width: to-rem(133);
         @include small-tablet {
            font-size: to-rem(10);
         }
      }
   }
}
.circle-btn {
   font-size: 1rem;
   position: relative;
   width: em(117);
   height: em(117);
   display: inline-flex;
   justify-content: center;
   align-items: center;
   @include small-tablet {
      width: em(80);
      height: em(80);
   }
   &__play {
      border-radius: 50%;
      width: em(83);
      height: em(83);
      @include small-tablet {
         width: em(60);
         height: em(60);
      }
   }
   &__text {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      animation: text-rotate 8s linear infinite;
      @keyframes text-rotate {
         from {
            transform: rotate(0deg);
         }
         to {
            transform: rotate(360deg);
         }
      }
   }
}
.introduce-benefits {
   display: flex;
   flex-direction: column;
   gap: to-rem(16);
   @include small-tablet-min {
      padding-right: em(20);
   }
   @include small-tablet {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
   }
   &__item {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      text-align: center;
      gap: em(7);
      flex: 0 0 em(90);
      width: em(90);
      min-height: em(90);
      padding: em(2.5);
      border: to-rem(1) solid #fff;
      border-radius: em(8);
      @include small-tablet {
         flex: 0 0 to-rem(90);
         width: to-rem(90);
         min-height: to-rem(90);
      }
      &_small.introduce-benefits__item {
         strong {
            font-size: em(22);
         }
      }
      strong {
         color: #fbb03c;
         font-size: em(28);
         @function em($one, $two: 28) {
            @return prc($one, $two, 1) + 0em;
         }
         line-height: 1.05;
         font-weight: 900;
         letter-spacing: em(1.5);
      }
      span {
         font-size: em(11);
         @function em($one, $two: 11) {
            @return prc($one, $two, 1) + 0em;
         }
         line-height: 1.1;
      }
   }
}
