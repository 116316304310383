/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
:root {
  --font-family: "Averta", sans-serif;
  --content-width: 110.125rem;
  --container-offset: 4.875rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
}
@media (max-width: 48rem) {
  :root {
    --container-offset: 0.75rem;
  }
}

@font-face {
  font-family: "Averta";
  src: url("../fonts/../fonts/Averta Cyrillic Thin.woff2") format("woff2");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Averta";
  src: url("../fonts/../fonts/Averta Cyrillic Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Averta";
  src: url("../fonts/../fonts/Averta Cyrillic Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Averta";
  src: url("../fonts/../fonts/Averta Cyrillic Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Averta";
  src: url("../fonts/../fonts/Averta Cyrillic Semi.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Averta";
  src: url("../fonts/../fonts/Averta Cyrillic Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Averta";
  src: url("../fonts/../fonts/Averta Cyrillic Black.woff2") format("woff2");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 20rem;
  min-height: 100%;
  font-size: calc(0.625rem + 0.375 * (100vw - 20rem) / 55);
}
@media (min-width: 75.0625rem) {
  .page__body {
    font-size: 1rem;
  }
}

.site-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1 1 auto;
}

a {
  text-decoration: none;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

[id] {
  scroll-margin-top: 6.25em;
}

body.resize * {
  animation: none !important;
  transition: none !important;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

@media (min-width: 22.5625rem) {
  .header__body,
  .introduce__body,
  .step__body,
  .graph-modal__container_thanks {
    zoom: 1.172;
  }
}
@media (min-width: 23.8125rem) {
  .header__body,
  .introduce__body,
  .step__body,
  .graph-modal__container_thanks {
    zoom: 1.2;
  }
}
@media (min-width: 100.0625rem) {
  .header__body,
  .introduce__body,
  .step__body,
  .graph-modal__container_thanks {
    zoom: 1.333;
  }
}
@media (min-width: 109.4375rem) {
  .header__body,
  .introduce__body,
  .step__body,
  .graph-modal__container_thanks {
    zoom: 1.458;
  }
}
@media (min-width: 118.8125rem) {
  .header__body,
  .introduce__body,
  .step__body,
  .graph-modal__container_thanks {
    zoom: 1.6;
  }
}

@media (min-width: 22.5625rem) {
  .range__slider > .noUi-base {
    zoom: 0.79;
  }
}
@media (min-width: 23.8125rem) {
  .range__slider > .noUi-base {
    zoom: 0.8;
  }
}
@media (min-width: 100.0625rem) {
  .range__slider > .noUi-base {
    zoom: 0.74;
  }
}
@media (min-width: 109.4375rem) {
  .range__slider > .noUi-base {
    zoom: 0.68;
  }
}
@media (min-width: 118.8125rem) {
  .range__slider > .noUi-base {
    zoom: 0.62;
  }
}

.btn {
  font-size: 1rem;
  color: #000000;
  border-radius: 0.625em;
  min-height: 3.125em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0.1875rem solid #fbb03c;
  background: #fbb03c;
  padding: 0 1.25em;
  transition: 0.3s;
}
@media (max-width: 36rem) {
  .btn {
    width: 100%;
  }
}
@media (any-hover: hover) {
  .btn:hover {
    background: transparent;
    color: #fbb03c;
  }
}
.btn > span {
  font-size: 0.875em;
  line-height: 1.5;
  font-weight: 900;
  letter-spacing: 0.1071em;
}

.input {
  font-size: 1.0625rem;
  border: 0.125rem solid rgb(255, 255, 255);
  background-color: transparent;
  border-radius: 0.5882em;
  min-height: 3.125rem;
  padding: 0 1.1765em;
  line-height: 1.33;
  width: 100%;
  outline: none;
  transition: 0.3s;
  color: #fff;
}
.input.just-validate-error-field {
  border-color: #ff8383;
}

.radio,
.check {
  font-size: 1rem;
  padding-left: 1.875em;
  cursor: pointer;
  position: relative;
  min-height: 1.25em;
}
.radio:before, .radio:after,
.check:before,
.check:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
}
.radio:before,
.check:before {
  font-size: 1.25em;
  border-color: rgba(251, 176, 60, 0.6);
  border-width: 0.125rem;
  border-style: solid;
}
.radio:after,
.check:after {
  transform: scale(0);
  transition: 0.3s;
}
.radio:has(input:checked):after,
.check:has(input:checked):after {
  transform: scale(1);
}
.radio:has(input:checked):before,
.check:has(input:checked):before {
  border-color: #fbb03c;
}
.radio input,
.check input {
  opacity: 0;
  width: 0;
  height: 0;
  font-size: 0;
}
.radio span,
.check span {
  font-size: 0.875em;
  font-weight: 700;
  line-height: 1.4286em;
}

.radio:before {
  border-radius: 50%;
}
.radio:after {
  font-size: 0.625em;
  top: 0.5em;
  left: 0.5em;
  border-radius: 50%;
  background: #fbb03c;
}

.check:after {
  font-size: 0.9375em;
  top: 0.1667em;
  left: 0.1667em;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fbb03c' viewBox='0 0 32 32'%3E%3Cpath d='M5 16.577l2.194-2.195 5.486 5.484L24.804 7.743 27 9.937l-14.32 14.32z'/%3E%3C/svg%3E");
}

.range {
  font-size: 1rem;
  padding-top: 2.875em;
}
.range__slider {
  margin: 0 0.625em;
  margin-bottom: 1.25em;
}
.range__inputs {
  display: none;
}
.range__limiters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.range__limiters span {
  font-size: 0.625em;
}
.range .noUi-base {
  font-size: calc(0.125rem + 0.875 * (100vw - 20rem) / 55);
}
@media (max-width: 48rem) {
  .range .noUi-base {
    font-size: 1rem;
  }
}
.range .noUi-connect {
  background: #fff;
}
.range .noUi-horizontal {
  height: 0.125em;
}
.range .noUi-horizontal .noUi-handle {
  width: 1.25em;
  height: 1.25em;
  border-radius: 50%;
  background: #fbb03c;
  cursor: pointer;
  right: -0.625em;
  top: -0.625em;
  box-shadow: none;
  border: none;
  outline: none;
}
.range .noUi-horizontal .noUi-handle:before, .range .noUi-horizontal .noUi-handle:after {
  display: none;
}
.range .noUi-tooltip {
  font-size: 0.75em;
  padding: 0.6667em 0.8333em;
  min-width: 3.3333em;
}

.file {
  cursor: pointer;
}
.file input {
  width: 0;
  height: 0;
  font-size: 0;
  opacity: 0;
}
.file > span span:last-child {
  display: none;
}
.file_uploaded > span span:first-child {
  display: none;
}
.file_uploaded > span span:last-child {
  display: inline-block;
}

input[hidden] {
  display: none;
}

.header {
  position: relative;
  z-index: 100;
  padding-top: 1.4375em;
  color: #ffffff;
  font-size: 1rem;
}
.header__body {
  display: flex;
  align-items: center;
  gap: 0.9375em;
}
@media (max-width: 48rem) {
  .header__body {
    justify-content: space-between;
  }
  body.js_quiz-started .header__body {
    flex-wrap: wrap;
  }
}
.header__logo img {
  width: 13.3125em;
  max-width: 100%;
}
@media (max-width: 29.9375rem) {
  .header__logo img {
    width: 8.0625em;
  }
}
@media (max-width: 39.9375rem) {
  .header__logo img {
    width: 11.8125em;
  }
}
@media (max-width: 59.9375rem) {
  .header__logo img {
    width: 10.125em;
  }
}
@media (max-width: 48rem) {
  .header__logo img {
    width: 8.0625rem;
  }
}
.header__center {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}
@media (max-width: 48rem) {
  .header__center {
    display: none;
  }
}
body.js_quiz-started .header__center {
  text-align: center;
  justify-content: center;
}
@media (max-width: 48rem) {
  body.js_quiz-started .header__center {
    display: block;
    order: 3;
  }
}
.header__sub-title {
  max-width: 14.5rem;
  color: #dbdbdb;
  font-size: 0.6875em;
  line-height: 1.2727em;
  font-weight: 300;
  letter-spacing: 0.0455em;
}
@media (max-width: 48rem) {
  .header__sub-title {
    font-size: 0.5rem;
  }
}
.header__sub-title_full {
  display: none;
  max-width: 27.5rem;
}
@media (min-width: 48.0625rem) {
  .header__sub-title_full {
    text-align: left;
  }
}
body.js_quiz-started .header__sub-title_short {
  display: none;
}
body.js_quiz-started .header__sub-title_full {
  display: block;
}
.header__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.header__phone {
  display: flex;
  align-items: center;
  gap: 0.3125em;
}
.header__phone img,
.header__phone svg {
  font-size: 2.0625em;
  width: 1em;
  height: 1em;
}
@media (max-width: 48rem) {
  .header__phone img,
  .header__phone svg {
    font-size: 1.4375rem;
  }
}
.header__phone span {
  font-size: 1.4375em;
  line-height: 1.55;
  font-weight: 600;
}
@media (max-width: 48rem) {
  .header__phone span {
    font-size: 0.875rem;
  }
}
.header__mail {
  text-align: right;
  font-size: 0.625em;
  line-height: 1.55;
  letter-spacing: 0.05em;
}
@media (max-width: 48rem) {
  .header__mail {
    display: inline-flex;
    flex-direction: column;
  }
}
.header__mail span {
  color: #fbb03c;
}

.quiz {
  position: relative;
  margin-top: calc(var(--header-height) * -1);
  padding-top: var(--header-height);
  color: #fff;
}
.quiz:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: rgba(15, 15, 15, 0.85);
}
.quiz > img,
.quiz > picture img,
.quiz > video,
.quiz > svg,
.quiz > iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 1;
}
.quiz__swiper {
  position: relative;
  z-index: 3;
}
.quiz__swiper .swiper-slide .introduce,
.quiz__swiper .swiper-slide .step {
  min-height: calc(var(--vh) * 100 - var(--header-height));
}
.quiz__swiper .swiper-slide:nth-child(2) .step-navigation__btn_prev,
.quiz__swiper .swiper-slide .swiper-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.quiz__swiper .swiper-slide:nth-child(2) .step-navigation__btn_prev img:first-child,
.quiz__swiper .swiper-slide:nth-child(2) .step-navigation__btn_prev svg:first-child,
.quiz__swiper .swiper-slide .swiper-button-disabled img:first-child,
.quiz__swiper .swiper-slide .swiper-button-disabled svg:first-child {
  opacity: 1;
}
.quiz__swiper .swiper-slide:nth-child(2) .step-navigation__btn_prev img:last-child,
.quiz__swiper .swiper-slide:nth-child(2) .step-navigation__btn_prev svg:last-child,
.quiz__swiper .swiper-slide .swiper-button-disabled img:last-child,
.quiz__swiper .swiper-slide .swiper-button-disabled svg:last-child {
  opacity: 0;
}

.introduce {
  padding: 5em 0.6875em;
  display: flex;
  flex-direction: column;
}
@media (max-width: 48rem) {
  .introduce {
    padding-top: 2.4375rem;
    padding-right: 0.8125rem;
    padding-left: 0.8125rem;
  }
}
.introduce__body {
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  gap: 1.25em;
  width: 100%;
}
@media (max-width: 48rem) {
  .introduce__body {
    flex-direction: column;
    gap: 1.125rem;
  }
}
.introduce__info {
  padding-top: 0.8125em;
  width: 100%;
}
@media (max-width: 48rem) {
  .introduce__info {
    padding-top: 0;
  }
}
.introduce__title {
  color: #ffffff;
  font-size: 2.25em;
  line-height: 1.45;
  font-weight: 900;
  letter-spacing: 0.0694em;
  text-transform: uppercase;
  max-width: 40.6875rem;
}
@media (max-width: 48rem) {
  .introduce__title {
    font-size: 1.25rem;
    max-width: calc(100% - 3.8889em);
  }
}
@media (min-width: 36.0625rem) {
  .introduce__title br {
    display: none;
  }
}
@media (max-width: 36rem) {
  .introduce__title {
    max-width: none;
  }
}
.introduce__title strong {
  display: inline;
  font-weight: 900;
  color: #fbb03c;
}
.introduce__row {
  display: flex;
  align-items: center;
  gap: 1.25em;
  justify-content: space-between;
  margin-top: -1.25em;
  margin-bottom: 0.9375em;
  max-width: 48.125rem;
}
@media (max-width: 48rem) {
  .introduce__row {
    flex-direction: column-reverse;
    align-items: flex-end;
    margin-top: -3.75rem;
    gap: 0;
    margin-bottom: 1.625rem;
  }
}
.introduce__list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25em 0.875em;
  max-width: 28.25rem;
}
@media (min-width: 48.0625rem) {
  .introduce__list {
    padding-top: 1.5625em;
  }
}
.introduce__list li {
  position: relative;
  font-size: 1.125rem;
  padding-left: 1.3333em;
  line-height: 1.55;
}
.introduce__list li:first-child {
  min-width: 6.6261em;
}
@media (max-width: 48rem) {
  .introduce__list li {
    font-size: 1rem;
  }
}
.introduce__list li:before {
  content: "";
  position: absolute;
  left: 0;
  font-size: 0.5556em;
  top: 0.9em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: #fbb03c;
}
.introduce__sub-title {
  position: relative;
  font-size: 1.125rem;
  padding-left: 1.0556em;
  line-height: 1.55;
  font-weight: 600;
  letter-spacing: 0.0278em;
  max-width: 39.5625rem;
  margin-bottom: 2.6667em;
}
@media (max-width: 48rem) {
  .introduce__sub-title {
    font-size: 0.875rem;
    margin-bottom: 2.0313rem;
  }
}
.introduce__sub-title:before {
  content: "";
  position: absolute;
  width: 0.125rem;
  height: 73%;
  border-radius: 0.125rem;
  top: 13.5%;
  left: 0;
  background: #fbb03c;
}
.introduce__bottom-row {
  display: flex;
  gap: 0.75em;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 36rem) {
  .introduce__bottom-row {
    justify-content: center;
    gap: 1.1875rem;
  }
}
.introduce__btn {
  margin-right: 0.75em;
  min-width: 16.25em;
}
@media (max-width: 36rem) {
  .introduce__btn {
    min-width: 0;
    order: 2;
    margin-right: 0;
    margin-bottom: 0.1875rem;
  }
}
.introduce__object {
  display: inline-flex;
  gap: 0.75em;
  align-items: center;
}
@media (max-width: 48rem) {
  .introduce__object {
    order: 3;
  }
}
.introduce__object img,
.introduce__object svg {
  font-size: 3.125em;
  width: 1em;
  height: 1em;
}
@media (max-width: 48rem) {
  .introduce__object img,
  .introduce__object svg {
    font-size: 2.25rem;
  }
}
.introduce__object span {
  font-size: 0.875rem;
  line-height: 1.1;
  font-weight: 500;
  letter-spacing: 0.0357em;
  max-width: 9.25rem;
}
@media (max-width: 48rem) {
  .introduce__object span {
    font-size: 0.75rem;
    max-width: 10.625rem;
  }
}
.introduce__time {
  position: relative;
  padding: 0.3125em 0;
  display: inline-flex;
  align-items: center;
}
@media (max-width: 48rem) {
  .introduce__time {
    order: 1;
  }
}
.introduce__time:before {
  content: "";
  position: absolute;
  width: 90%;
  left: 5%;
  height: 0.0625rem;
  bottom: 0;
  background: #fff;
}
@media (max-width: 48rem) {
  .introduce__time:before {
    width: 80%;
  }
}
.introduce__time img,
.introduce__time svg {
  font-size: 2.375em;
  width: 1em;
  height: 1m;
}
@media (max-width: 48rem) {
  .introduce__time img,
  .introduce__time svg {
    font-size: 2.375rem;
  }
}
.introduce__time span {
  font-size: 0.8125rem;
  font-family: "Averta", Arial, sans-serif;
  line-height: 1.4;
  font-weight: 480;
  letter-spacing: 0.0769em;
  max-width: 8.3125rem;
}
@media (max-width: 48rem) {
  .introduce__time span {
    font-size: 0.625rem;
  }
}

.circle-btn {
  font-size: 1rem;
  position: relative;
  width: 7.3125em;
  height: 7.3125em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 48rem) {
  .circle-btn {
    width: 5em;
    height: 5em;
  }
}
.circle-btn__play {
  border-radius: 50%;
  width: 5.1875em;
  height: 5.1875em;
}
@media (max-width: 48rem) {
  .circle-btn__play {
    width: 3.75em;
    height: 3.75em;
  }
}
.circle-btn__text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation: text-rotate 8s linear infinite;
}
@keyframes text-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.introduce-benefits {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 48.0625rem) {
  .introduce-benefits {
    padding-right: 1.25em;
  }
}
@media (max-width: 48rem) {
  .introduce-benefits {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
.introduce-benefits__item {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  text-align: center;
  gap: 0.4375em;
  flex: 0 0 5.625em;
  width: 5.625em;
  min-height: 5.625em;
  padding: 0.1563em;
  border: 0.0625rem solid #fff;
  border-radius: 0.5em;
}
@media (max-width: 48rem) {
  .introduce-benefits__item {
    flex: 0 0 5.625rem;
    width: 5.625rem;
    min-height: 5.625rem;
  }
}
.introduce-benefits__item_small.introduce-benefits__item strong {
  font-size: 1.375em;
}
.introduce-benefits__item strong {
  color: #fbb03c;
  font-size: 1.75em;
  line-height: 1.05;
  font-weight: 900;
  letter-spacing: 0.0536em;
}
.introduce-benefits__item span {
  font-size: 0.6875em;
  line-height: 1.1;
}

.step {
  padding-top: 5em;
  padding-bottom: 7.5em;
  display: flex;
  flex-direction: column;
}
@media (max-width: 48rem) {
  .step {
    padding-right: 0.6875rem;
    padding-left: 0.6875rem;
    padding-top: 0.9375rem;
  }
  .swiper-slide:nth-child(3) .step {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
.step__body {
  display: grid;
  grid-template-columns: 19.875rem 1fr;
  flex: 1 1 auto;
  width: 100%;
}
@media (max-width: 80rem) {
  .step__body {
    gap: 1.1875rem;
    grid-template-columns: 12.5rem 1fr;
  }
}
@media (max-width: 48rem) {
  .step__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.1875rem;
  }
}
.step__bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (max-width: 48rem) {
  .step__bottom {
    align-items: center;
    padding-top: 2.1875rem;
  }
}
@media (max-width: 48rem) {
  .step .step-info_grid + .step__bottom {
    padding-top: 0.9375rem;
  }
}

.step-aside {
  display: flex;
  flex-direction: column;
}
@media (min-width: 48.0625rem) {
  .step-aside {
    margin-bottom: 3rem;
  }
}
@media (min-width: 48.0625rem) {
  .step-aside_last .step-aside__title {
    padding-left: 0.3125em;
  }
}
.step-aside__logo {
  width: 14.1875em;
  margin-bottom: 2.6875em;
}
@media (max-width: 48rem) {
  .step-aside__logo {
    width: 11.875rem;
    align-self: center;
    margin-bottom: 0.9375rem;
  }
}
.step-aside__logo img {
  max-width: 100%;
}
.step-aside__count {
  display: inline-flex;
  align-items: flex-end;
  font-size: 3.1875em;
  font-weight: 900;
  margin-bottom: 0.7843em;
}
@media (min-width: 48.0625rem) {
  .step-aside__count {
    padding-top: 0.1961em;
  }
}
@media (max-width: 48rem) {
  .step-aside__count {
    align-self: center;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
}
.step-aside__count strong {
  color: #fbb03c;
  font-size: 1.7451em;
  font-weight: 900;
  margin-bottom: -0.1em;
}
@media (max-width: 48rem) {
  .step-aside__count strong {
    font-size: 4.375rem;
  }
}
.step-aside__title {
  font-size: 2.125em;
  line-height: 1.1;
  font-weight: 700;
  letter-spacing: 0.0441em;
  max-width: 16.125rem;
}
@media (max-width: 48rem) {
  .step-aside__title {
    text-align: center;
    align-self: center;
  }
}

.step-navigation {
  display: flex;
  gap: 1.25rem;
  font-size: 1rem;
}
.step-navigation__btn {
  user-select: none;
  flex-shrink: 0;
  font-size: 4.375em;
  width: 1em;
  height: 1em;
  border-radius: 0.0714em;
  overflow: hidden;
  position: relative;
}
.step-navigation__btn_prev {
  transform: rotate(180deg);
}
@media (any-hover: hover) {
  .step-navigation__btn:hover > img:first-child,
  .step-navigation__btn:hover > svg:first-child {
    opacity: 1;
  }
  .step-navigation__btn:hover > img:last-child,
  .step-navigation__btn:hover > svg:last-child {
    opacity: 0;
  }
}
.step-navigation__btn > img,
.step-navigation__btn > svg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.3s;
}
.step-navigation__btn > img:first-child,
.step-navigation__btn > svg:first-child {
  opacity: 0;
}

.step-info {
  display: flex;
}
@media (min-width: 48.0625rem) {
  .step-info {
    grid-row: span 2;
  }
}
@media (max-width: 64rem) {
  .step-info {
    flex-direction: column;
  }
}
.step-info_grid {
  display: grid;
  grid-template-columns: 23.625rem 1fr;
}
@media (min-width: 64.0625rem) {
  .step-info_grid {
    grid-template-rows: min-content 1fr min-content;
  }
}
@media (max-width: 64rem) {
  .step-info_grid {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 48rem) {
  .step-info_padding-0 {
    width: 100%;
  }
}
@media (min-width: 48.0625rem) {
  .step-info_padding-0 {
    padding-top: 1.25em;
  }
  .step-info_padding-0 .step-info__main {
    padding-top: 0.625em;
  }
}
@media (min-width: 48.0625rem) {
  .step-info_padding-1 {
    padding-top: 4.5em;
  }
}
@media (min-width: 48.0625rem) {
  .step-info_padding-2 {
    padding-top: 3.4375em;
  }
}
.step-info__main {
  flex: 1 1 auto;
}
@media (max-width: 48rem) {
  .step-info__dop-input {
    display: flex;
    flex-direction: column;
  }
}
.step-info__dop-input span {
  font-size: 0.875rem;
  font-weight: 700;
  padding-bottom: 0.5714em;
  padding-right: 0.7143em;
}
@media (max-width: 48rem) {
  .step-info__dop-input span {
    font-size: 1rem;
    padding-bottom: 0.625rem;
    padding-right: 0;
  }
}
@media (max-width: 48rem) {
  .step-info__input {
    width: auto;
    font-size: 0.75rem;
  }
}
.step-info__inputs {
  display: flex;
  flex-direction: column;
  gap: 0.625em;
  margin-bottom: 2.75em;
}
.step-info__inputs:last-child {
  margin: 0;
}
.step-info__inputs,
.step-info__inputs label:not(.file) span {
  font-size: 1.125rem;
  font-weight: 700;
}
@media (max-width: 48rem) {
  .step-info__inputs,
  .step-info__inputs label:not(.file) span {
    font-size: 1rem;
  }
}
.step-info__inputs-title {
  margin-bottom: 0.3125em;
}
.step-info__range {
  width: 100%;
  max-width: 25.125rem;
  margin-bottom: 1.25rem;
}
.step-info__range:last-child {
  margin: 0;
}
.step-info__title {
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 0.3125rem;
}
.step-info__radio_file:has(+ .step-info__file) + .step-info__file {
  display: none;
}
.step-info__radio_file:has(+ .step-info__file):has(input:checked) + .step-info__file {
  display: inline-flex;
  align-self: flex-start;
}
@media (max-width: 48rem) {
  .step-info__radio_file:has(+ .step-info__file):has(input:checked) + .step-info__file {
    align-self: stretch;
  }
}
.step-info__file span {
  font-weight: 700;
  font-size: 1rem;
}
.step-info__desc-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 48.0625rem) {
  .step-info__desc-outer {
    margin-left: 1.875em;
  }
}
@media (max-width: 48rem) {
  .step-info__desc-outer {
    order: 2;
    padding: 1.75rem 0;
    text-align: center;
  }
}
.step-info__desc {
  font-size: 0.75rem;
  line-height: 1.25;
  font-weight: 500;
  max-width: 16.5625rem;
}
@media (min-width: 48.0625rem) {
  .step-info__desc {
    margin-bottom: 7.5em;
  }
}
@media (max-width: 48rem) {
  .step-info__desc {
    font-size: 0.625rem;
    letter-spacing: 0.0625rem;
  }
}
.step-info__bu-outer {
  display: flex;
  align-items: center;
}
@media (min-width: 48.0625rem) {
  .step-info__bu-outer {
    margin-left: 3.0625em;
  }
}
@media (max-width: 48rem) {
  .step-info__bu-outer_pc {
    display: none;
  }
}
@media (min-width: 48.0625rem) {
  .step-info__bu-outer_mob {
    display: none;
  }
}
@media (max-width: 48rem) {
  .step-info__bu-outer_mob {
    padding-top: 2.3125rem;
  }
}
.step-info__bu {
  font-size: 0.625rem;
  line-height: 1.55;
}
@media (max-width: 48rem) {
  .step-info__bu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.step-info__bu img {
  font-size: 2em;
  width: 1em;
  height: 1em;
}

.step-icons-radio {
  gap: 0.3125rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  max-width: 28.375rem;
  margin-bottom: 1.25rem;
}
@media (max-width: 48rem) {
  .step-icons-radio {
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }
}
.step-icons-radio__radio {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-top: 0.4375em;
}
.step-icons-radio__radio:before {
  content: "";
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  font-size: 1.875rem;
  left: -0.2333em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: #fbb03c;
  background-position: center center;
  background-size: 0.5em;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 76.887 55.552'%3E%3Cpath fill='%23ffffff' d='M34.373 49.541L76.887 5.889 70.739 0 28.426 43.458 6.078 20.632 0 26.585l22.488 22.972 6.029 5.995-.091-.085.091.085 5.856-6.011z'/%3E%3C/svg%3E");
}
.step-icons-radio__radio:has(input:checked):before {
  opacity: 1;
  visibility: visible;
}
.step-icons-radio__radio input {
  width: 0;
  height: 0;
  opacity: 0;
  font-size: 0;
  pointer-events: none;
}
.step-icons-radio__radio img,
.step-icons-radio__radio svg {
  font-size: 6.4688em;
  width: 1em;
  height: 1em;
}
@media (max-width: 48rem) {
  .step-icons-radio__radio img,
  .step-icons-radio__radio svg {
    font-size: 5.4375rem;
  }
}
.step-icons-radio__radio span {
  font-size: 0.8125rem;
  margin: 0.3846em 0;
  line-height: 1.1;
}
@media (max-width: 48rem) {
  .step-icons-radio__radio span {
    font-size: 0.625rem;
    padding-right: 0.1875rem;
  }
}

@media (max-width: 48rem) {
  .info-right {
    padding-top: 2.75rem;
  }
}
.info-right__title {
  font-size: 1.25rem;
  line-height: 1.55;
  font-weight: 700;
  margin-bottom: 0.1563rem;
}
.info-right__value {
  font-size: 3rem;
  color: #fbb03c;
  font-weight: 900;
  margin-bottom: 0.9375em;
}
.info-right__items {
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
}
.info-right__caption {
  font-size: 1.25rem;
  padding-bottom: 1em;
  font-weight: 700;
}
.info-right__inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3125em;
}
.info-right__inputs:last-child {
  margin: 0;
}
.info-right__inputs_radio {
  gap: 0.625em;
}
.info-right__inputs_check {
  gap: 0.3125em;
}
.step-benefits {
  display: flex;
  flex-wrap: wrap;
  gap: 1.4375em 4.375em;
  margin-bottom: 2.6875em;
}
@media (min-width: 48.0625rem) {
  .step-benefits {
    grid-column: span 2;
  }
}
@media (max-width: 48rem) {
  .step-benefits {
    order: 1;
    flex-direction: column;
    margin-bottom: 0;
  }
}
.step-benefits__item {
  display: flex;
  align-items: center;
  gap: 0.9375em;
}
@media (max-width: 48rem) {
  .step-benefits__item:nth-child(2) img {
    transform: translateX(-10%);
  }
}
.step-benefits__item img {
  font-size: 5.625em;
  width: 1em;
  height: 1em;
}
@media (max-width: 48rem) {
  .step-benefits__item img {
    font-size: 5.125rem;
  }
}
.step-benefits__item p {
  font-size: 1.25em;
  line-height: 1.1;
  font-weight: 700;
}
@media (max-width: 48rem) {
  .step-benefits__item p {
    font-size: 0.9375rem;
    line-height: 1.25rem;
  }
}
.step-benefits__item p strong {
  color: #fbb03c;
  font-weight: 700;
}

.step-form {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  max-width: 23.625rem;
  width: 100%;
}
@media (min-width: 48.0625rem) {
  .step-form {
    margin-left: 0.625em;
    grid-row: span 2;
  }
}
@media (max-width: 48rem) {
  .step-form {
    order: 3;
    margin: 0 -0.625rem;
    width: calc(100% + 1.25rem);
  }
}
.step-form__label {
  display: flex;
  flex-direction: column;
}
.step-form__label span {
  font-size: 0.875rem;
  color: #fbb03c;
  padding-bottom: 0.3571em;
  font-weight: 700;
}
.step-form__label_regions {
  position: relative;
}
.step-form__regions {
  font-size: 1rem;
  position: absolute;
  width: 100%;
  max-height: 9.375rem;
  background: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  visibility: hidden;
  overflow-y: auto;
  top: calc(100% + 0.3125rem);
  left: 0;
  transition: 0.3s;
  opacity: 0;
  border-radius: 0.625em;
  z-index: 10;
}
.step-form__regions.--show {
  opacity: 1;
  visibility: visible;
}
.step-form__regions li {
  cursor: pointer;
  padding: 0.625em 0.3125em;
}
.step-form__input {
  width: auto;
  min-height: 2.5rem;
}
.step-form__submit {
  min-height: 2.5rem;
}
.step-form__submit span {
  font-weight: 700;
  letter-spacing: normal;
  font-size: 0.9375rem;
}
.step-form__policy {
  font-size: 0.625rem;
  line-height: 1.4;
  text-align: center;
  align-self: center;
  max-width: 10.875rem;
}
.step-form__policy a {
  text-decoration: underline;
}
@media (any-hover: hover) {
  .step-form__policy a:hover {
    text-decoration: none;
  }
}

.graph-modal__close {
  background: none;
}

.graph-modal__container {
  padding: 4.375em 1.5rem;
  max-width: 36.5625rem;
}
.graph-modal__container_success {
  padding: 2.5em 1.5rem;
}
.graph-modal__close {
  font-size: 1.5rem;
  right: 1.0417em;
  top: 1.0417em;
  width: 1em;
  height: 1em;
}
.popup {
  text-align: center;
  max-width: 31.25rem;
  margin: 0 auto;
}
.popup__icon {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}
.popup__icon svg,
.popup__icon img {
  width: 1em;
  flex-shrink: 0;
  height: 1em;
}
.popup__title {
  color: rgb(0, 0, 0);
  font-size: 2em;
  font-weight: 700;
  line-height: 1.2188em;
  margin-bottom: 0.4375em;
}
@media (max-width: 48rem) {
  .popup__title {
    font-size: 1.5rem;
  }
}
.popup__title + .btn {
  margin-top: 0.7813em;
}
.popup__sub-title {
  color: rgb(0, 0, 0);
  font-size: 1.125em;
  font-weight: 500;
  line-height: 1.1667em;
}
@media (max-width: 48rem) {
  .popup__sub-title {
    font-size: 1rem;
  }
}
.popup__sub-title + .btn {
  margin-top: 1.3889em;
}

[data-graph-target=pop] {
  padding: 0;
  background: none;
  border-radius: 1em;
  overflow: hidden;
  max-width: 90%;
  width: 100%;
  max-height: 90vh;
}
[data-graph-target=pop] video,
[data-graph-target=pop] iframe {
  position: relative;
  height: auto;
  width: 100%;
  aspect-ratio: 16/9;
}
[data-graph-target=pop] .graph-modal__close {
  font-size: 2.5em;
  z-index: 10;
  top: 0.2em;
  right: 0.2em;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  padding: 0.3125em;
  width: 1.25em;
  height: 1.25em;
}
[data-graph-target=pop] .graph-modal__close img {
  max-width: 100%;
}
[data-graph-target=pop] .graph-modal__content {
  padding: 0;
}

.graph-modal__container_thanks {
  font-size: 1rem;
  padding: 2.3125em 1.5em;
  max-width: 17.5rem;
}
.graph-modal__container_thanks .graph-modal__close {
  font-size: 1em;
  right: 0.7917em;
  top: 0.7917em;
  width: 1em;
  height: 1em;
}
.graph-modal__container_thanks .graph-modal__close img {
  max-width: 100%;
}
.thanks {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.thanks__icon {
  font-size: 5em;
  width: 1em;
  height: 1em;
  margin-bottom: 0.2375em;
}
.thanks__icon img {
  max-width: 100%;
}
.thanks__title {
  margin-bottom: 0.5em;
  color: #000000;
  font-size: 0.875em;
  line-height: 1.55;
  font-weight: 600;
}
.thanks__sub-title {
  margin-bottom: 1.4167em;
}
.thanks__sub-title, .thanks__link {
  color: #000000;
  font-size: 0.75em;
  line-height: 1.55;
}
.thanks__socials {
  margin-bottom: 1.1875em;
  display: flex;
  gap: 0.625em;
}
.thanks__socials img {
  font-size: 1.875em;
  width: 1em;
  height: 1em;
}
.thanks__socials + .thanks__sub-title {
  margin-bottom: 0.3125em;
}
.thanks__link {
  text-decoration: underline;
}
@media (any-hover: hover) {
  .thanks__link:hover {
    text-decoration: none;
  }
}