.step {
   padding-top: em(80);
   padding-bottom: em(120);
   display: flex;
   flex-direction: column;
   @include small-tablet {
      padding-right: to-rem(11);
      padding-left: to-rem(11);
      padding-top: to-rem(15);
      .swiper-slide:nth-child(3) & {
         padding-right: to-rem(8);
         padding-left: to-rem(8);
      }
   }
   &__body {
      display: grid;
      grid-template-columns: to-rem(318) 1fr;
      flex: 1 1 auto;
      width: 100%;
      @include desktop {
         gap: to-rem(19);
         grid-template-columns: to-rem(200) 1fr;
      }
      @include small-tablet {
         display: flex;
         flex-direction: column;
         align-items: center;
         gap: to-rem(19);
      }
   }
   &__bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      @include small-tablet {
         align-items: center;
         padding-top: to-rem(35);
      }
   }
   .step-info_grid + .step__bottom {
      @include small-tablet {
         padding-top: to-rem(15);
      }
   }
}
.step-aside {
   display: flex;
   flex-direction: column;
   @include small-tablet-min {
      margin-bottom: to-rem(48);
   }
   &_last {
      .step-aside {
         &__title {
            @include small-tablet-min {
               padding-left: em(5);
            }
         }
      }
   }
   &__logo {
      width: em(227);
      margin-bottom: em(43);
      @include small-tablet {
         width: to-rem(190);
         align-self: center;
         margin-bottom: to-rem(15);
      }
      img {
         max-width: 100%;
      }
   }
   &__count {
      display: inline-flex;
      align-items: flex-end;
      font-size: em(51);
      @function em($one, $two: 51) {
         @return prc($one, $two, 1) + 0em;
      }
      font-weight: 900;
      margin-bottom: em(40);
      @include small-tablet-min {
         padding-top: em(10);
      }
      @include small-tablet {
         align-self: center;
         font-size: to-rem(40);
         margin-bottom: to-rem(16);
      }
      strong {
         color: #fbb03c;
         font-size: em(89);
         @function em($one, $two: 89) {
            @return prc($one, $two, 1) + 0em;
         }
         font-weight: 900;
         margin-bottom: -0.1em;
         @include small-tablet {
            font-size: to-rem(70);
         }
      }
   }
   &__title {
      font-size: em(34);
      @function em($one, $two: 34) {
         @return prc($one, $two, 1) + 0em;
      }
      line-height: 1.1;
      font-weight: 700;
      letter-spacing: em(1.5);
      max-width: to-rem(258);
      @include small-tablet {
         text-align: center;
         align-self: center;
      }
   }
}
.step-navigation {
   display: flex;
   gap: to-rem(20);
   font-size: 1rem;
   &__btn {
      user-select: none;
      flex-shrink: 0;
      font-size: em(70);
      @function em($one, $two: 70) {
         @return prc($one, $two, 1) + 0em;
      }
      width: 1em;
      height: 1em;
      border-radius: em(5);
      overflow: hidden;
      position: relative;
      &_prev {
         transform: rotate(180deg);
      }
      @media (any-hover: hover) {
         &:hover {
            > img,
            > svg {
               &:first-child {
                  opacity: 1;
               }
               &:last-child {
                  opacity: 0;
               }
            }
         }
      }
      > img,
      > svg {
         position: absolute;
         width: 100%;
         height: 100%;
         top: 0;
         left: 0;
         @include transition();
         &:first-child {
            opacity: 0;
         }
      }
   }
}
.step-info {
   @include small-tablet-min {
      grid-row: span 2;
   }
   display: flex;
   @include tablet {
      flex-direction: column;
   }
   &_grid {
      display: grid;
      grid-template-columns: to-rem(378) 1fr;
      @include tablet-min {
         grid-template-rows: min-content 1fr min-content;
      }
      @include tablet {
         grid-template-columns: 1fr;
      }
   }
   &_padding-0 {
      @include small-tablet {
         width: 100%;
      }
      @include small-tablet-min {
         padding-top: em(20);
         .step-info__main {
            padding-top: em(10);
         }
      }
   }
   &_padding-1 {
      @include small-tablet-min {
         padding-top: em(72);
      }
   }
   &_padding-2 {
      @include small-tablet-min {
         padding-top: em(55);
      }
   }
   &__main {
      flex: 1 1 auto;
   }
   &__dop-input {
      @include small-tablet {
         display: flex;
         flex-direction: column;
      }
      span {
         font-size: to-rem(14);
         @function em($one, $two: 14) {
            @return prc($one, $two, 1) + 0em;
         }
         font-weight: 700;
         padding-bottom: em(8);
         padding-right: em(10);
         @include small-tablet {
            font-size: to-rem(16);
            padding-bottom: to-rem(10);
            padding-right: 0;
         }
      }
   }
   &__input {
      @include small-tablet {
         width: auto;
         font-size: to-rem(12);
      }
   }
   &__inputs {
      display: flex;
      flex-direction: column;
      gap: em(10);
      margin-bottom: em(44);
      &:last-child {
         margin: 0;
      }
      &,
      label:not(.file) span {
         font-size: to-rem(18);
         @function em($one, $two: 18) {
            @return prc($one, $two, 1) + 0em;
         }
         font-weight: 700;
         @include small-tablet {
            font-size: to-rem(16);
         }
      }
   }
   &__inputs-title {
      margin-bottom: em(5);
   }
   &__range {
      width: 100%;
      max-width: to-rem(402);
      margin-bottom: to-rem(20);
      &:last-child {
         margin: 0;
      }
   }
   &__title {
      font-size: to-rem(16);
      @function em($one, $two: 16) {
         @return prc($one, $two, 1) + 0em;
      }
      font-weight: 700;
      padding-bottom: to-rem(5);
   }
   &__radio {
      &_file {
         &:has(+ .step-info__file) {
            & + .step-info__file {
               display: none;
            }
            &:has(input:checked) + .step-info__file {
               display: inline-flex;
               align-self: flex-start;
               @include small-tablet {
                  align-self: stretch;
               }
            }
         }
      }
   }
   &__file {
      span {
         font-weight: 700;
         font-size: to-rem(16);
      }
   }
   &__desc-outer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include small-tablet-min {
         margin-left: em(30);
      }
      @include small-tablet {
         order: 2;
         padding: to-rem(28) 0;
         text-align: center;
      }
   }
   &__desc {
      font-size: to-rem(12);
      @function em($one, $two: 12) {
         @return prc($one, $two, 1) + 0em;
      }
      line-height: 1.25;
      font-weight: 500;
      max-width: to-rem(265);
      @include small-tablet-min {
         margin-bottom: em(90);
      }
      @include small-tablet {
         font-size: to-rem(10);
         letter-spacing: to-rem(1);
      }
   }
   &__bu-outer {
      display: flex;
      align-items: center;
      @include small-tablet-min {
         margin-left: em(49);
      }
      &_pc {
         @include small-tablet {
            display: none;
         }
      }
      &_mob {
         @include small-tablet-min {
            display: none;
         }
         @include small-tablet {
            padding-top: to-rem(37);
         }
      }
   }
   &__bu {
      font-size: to-rem(10);
      @function em($one, $two: 10) {
         @return prc($one, $two, 1) + 0em;
      }
      line-height: 1.55;
      @include small-tablet {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         text-align: center;
      }
      img {
         font-size: em(20);
         width: 1em;
         height: 1em;
      }
   }
}
.step-icons-radio {
   gap: to-rem(5);
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   @include small-tablet {
      grid-template-columns: repeat(3, 1fr);
      gap: to-rem(8);
   }
   width: 100%;
   max-width: to-rem(454);
   margin-bottom: to-rem(20);
   &__radio {
      position: relative;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      padding-top: em(7);
      &:before {
         content: "";
         position: absolute;
         top: 0;
         opacity: 0;
         visibility: hidden;
         @include transition();
         font-size: to-rem(30);
         @function em($one, $two: 30) {
            @return prc($one, $two, 1) + 0em;
         }
         left: em(-7);
         width: 1em;
         height: 1em;
         border-radius: 50%;
         background: $theme-1;
         background-position: center center;
         background-size: 0.5em;
         background-repeat: no-repeat;
         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 76.887 55.552'%3E%3Cpath fill='%23ffffff' d='M34.373 49.541L76.887 5.889 70.739 0 28.426 43.458 6.078 20.632 0 26.585l22.488 22.972 6.029 5.995-.091-.085.091.085 5.856-6.011z'/%3E%3C/svg%3E");
      }
      &:has(input:checked) {
         &:before {
            opacity: 1;
            visibility: visible;
         }
      }
      input {
         width: 0;
         height: 0;
         opacity: 0;
         font-size: 0;
         pointer-events: none;
      }
      img,
      svg {
         font-size: em(103.5);
         @function em($one, $two: 103.5) {
            @return prc($one, $two, 1) + 0em;
         }
         width: 1em;
         height: 1em;
         @include small-tablet {
            font-size: to-rem(87);
         }
      }
      span {
         font-size: to-rem(13);
         @function em($one, $two: 13) {
            @return prc($one, $two, 1) + 0em;
         }
         margin: em(5) 0;
         line-height: 1.1;
         @include small-tablet {
            font-size: to-rem(10);
            padding-right: to-rem(3);
         }
      }
   }
}
.info-right {
   @include small-tablet {
      padding-top: to-rem(44);
   }
   &__title {
      font-size: to-rem(20);
      @function em($one, $two: 20) {
         @return prc($one, $two, 1) + 0em;
      }
      line-height: 1.55;
      font-weight: 700;
      margin-bottom: to-rem(2.5);
   }
   &__value {
      font-size: to-rem(48);
      @function em($one, $two: 48) {
         @return prc($one, $two, 1) + 0em;
      }
      color: $theme-1;
      font-weight: 900;
      margin-bottom: em(45);
   }
   &__items {
      display: flex;
      flex-direction: column;
      gap: to-rem(5);
   }
   &__item {
   }
   &__caption {
      font-size: to-rem(20);
      @function em($one, $two: 20) {
         @return prc($one, $two, 1) + 0em;
      }
      padding-bottom: em(20);
      font-weight: 700;
   }
   &__inputs {
      display: flex;
      flex-direction: column;
      margin-bottom: em(5);
      &:last-child {
         margin: 0;
      }
      &_radio {
         gap: em(10);
      }
      &_check {
         gap: em(5);
      }
   }
   &__radio {
   }
   &__check {
   }
}
.step-benefits {
   display: flex;
   flex-wrap: wrap;
   gap: em(23) em(70);
   margin-bottom: em(43);
   @include small-tablet-min {
      grid-column: span 2;
   }
   @include small-tablet {
      order: 1;
      flex-direction: column;
      margin-bottom: 0;
   }
   &__item {
      display: flex;
      align-items: center;
      gap: em(15);
      &:nth-child(2) {
         img {
            @include small-tablet {
               transform: translateX(-10%);
            }
         }
      }
      img {
         font-size: em(90);
         @function em($one, $two: 80) {
            @return prc($one, $two, 1) + 0em;
         }
         width: 1em;
         height: 1em;
         @include small-tablet {
            font-size: to-rem(82);
         }
      }
      p {
         font-size: em(20);
         @function em($one, $two: 20) {
            @return prc($one, $two, 1) + 0em;
         }
         line-height: 1.1;
         font-weight: 700;
         @include small-tablet {
            font-size: to-rem(15);
            line-height: to-rem(20);
         }
         strong {
            color: $theme-1;
            font-weight: 700;
         }
      }
   }
}
.step-form {
   display: flex;
   flex-direction: column;
   gap: to-rem(10);
   max-width: to-rem(378);
   width: 100%;
   @include small-tablet-min {
      margin-left: em(10);
      grid-row: span 2;
   }
   @include small-tablet {
      order: 3;
      margin: 0 to-rem(-10);
      width: calc(100% + to-rem(20));
   }
   &__label {
      display: flex;
      flex-direction: column;

      span {
         font-size: to-rem(14);
         @function em($one, $two: 14) {
            @return prc($one, $two, 1) + 0em;
         }
         color: $theme-1;
         padding-bottom: em(5);
         font-weight: 700;
      }
      &_regions {
         position: relative;
      }
   }
   &__regions {
      font-size: 1rem;
      position: absolute;
      width: 100%;
      max-height: to-rem(150);
      background: rgba(#000, 0.9);
      overflow: hidden;
      visibility: hidden;
      overflow-y: auto;
      top: calc(100% + to-rem(5));
      left: 0;
      @include transition();
      opacity: 0;
      border-radius: em(10);
      z-index: 10;
      &.--show {
         opacity: 1;
         visibility: visible;
      }
      li {
         cursor: pointer;
         padding: em(10) em(5);
      }
   }
   &__input {
      width: auto;
      min-height: to-rem(40);
   }
   &__submit {
      min-height: to-rem(40);

      span {
         font-weight: 700;
         letter-spacing: normal;
         font-size: to-rem(15);
      }
   }
   &__policy {
      font-size: to-rem(10);
      @function em($one, $two: 10) {
         @return prc($one, $two, 1) + 0em;
      }
      line-height: 1.4;
      text-align: center;
      align-self: center;
      max-width: to-rem(174);
      a {
         text-decoration: underline;
         @media (any-hover: hover) {
            &:hover {
               text-decoration: none;
            }
         }
      }
   }
}
