.header {
   position: relative;
   z-index: 100;
   padding-top: em(23);
   color: #ffffff;
   font-size: 1rem;
   &__body {
      display: flex;
      align-items: center;
      gap: em(15);
      @include small-tablet {
         justify-content: space-between;
         body.js_quiz-started & {
            flex-wrap: wrap;
         }
      }
   }
   &__logo {
      img {
         width: em(213);
         max-width: 100%;
         @media (max-width: to-rem(479)) {
            width: em(129);
         }
         @media (max-width: to-rem(639)) {
            width: em(189);
         }
         @media (max-width: to-rem(959)) {
            width: em(162);
         }
         @include small-tablet {
            width: to-rem(129);
         }
      }
   }
   &__center {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      @include small-tablet {
         display: none;
      }
      body.js_quiz-started & {
         text-align: center;
         justify-content: center;
         @include small-tablet {
            display: block;
            order: 3;
         }
      }
   }
   &__sub-title {
      max-width: to-rem(232);
      color: #dbdbdb;
      font-size: em(11);
      @function em($one, $two: 11) {
         @return prc($one, $two, 1) + 0em;
      }
      line-height: em(14);
      font-weight: 300;
      letter-spacing: em(0.5);
      @include small-tablet {
         font-size: to-rem(8);
      }
      &_full {
         display: none;
         max-width: to-rem(440);
         @include small-tablet-min {
            text-align: left;
         }
      }
      body.js_quiz-started & {
         &_short {
            display: none;
         }
         &_full {
            display: block;
         }
      }
   }
   &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
   }
   &__phone {
      display: flex;
      align-items: center;
      gap: em(5);
      img,
      svg {
         font-size: em(33);
         @include small-tablet {
            font-size: to-rem(23);
         }
         @function em($one, $two: 33) {
            @return prc($one, $two, 1) + 0em;
         }
         width: 1em;
         height: 1em;
      }
      span {
         font-size: em(23);
         line-height: 1.55;
         font-weight: 600;
         @include small-tablet {
            font-size: to-rem(14);
         }
      }
   }
   &__mail {
      text-align: right;
      font-size: em(10);
      @function em($one, $two: 10) {
         @return prc($one, $two, 1) + 0em;
      }
      line-height: 1.55;
      letter-spacing: em(0.5);
      @include small-tablet {
         display: inline-flex;
         flex-direction: column;
      }
      span {
         color: $theme-1;
      }
   }
}
