// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
   // base
   --font-family: "Averta", sans-serif;
   --content-width: #{to-rem(1762)};
   --container-offset: #{to-rem(78)};
   @include small-tablet {
      --container-offset: #{to-rem(12)};
   }
   --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

   // colors
   --light-color: #fff;
}
$designWidth: 1200;
$theme-1: #fbb03c;
