@mixin media-query($value: $md3, $type: max) {
   @if $type == max {
      @media (max-width: to-rem($value)) {
         @content;
      }
   } @else {
      @media (min-width: to-rem($value + 1)) {
         @content;
      }
   }
}
@function grn($number, $decimal: 4, $mode: round) {
   // Округлить число
   $n: 1;
   @for $i from 1 through $decimal {
      $n: $n * 10;
   }
   @if $mode == round {
      @return math.div(round($number * $n), $n);
   } @else if $mode == ceil {
      @return math.div(ceil($number * $n), $n);
   } @else {
      @return math.div(floor($number * $n), $n);
   }
}
@function prc($one, $two: $designWidth, $type: 0) {
   $result: math.div($one, $two);
   @if $type != 0 {
      // Часть от числа
      @return grn($result);
   }
   // Процент от числа
   @return grn($result * 100%);
}
@function to-rem($one, $type: 0) {
   $result: prc($one, 16, 1);
   @if $type != 0 {
      // Число
      @return $result;
   }
   @return $result + 0rem;
}
@function em($one, $two: 16, $type: 0) {
   $result: prc($one, $two, 1);
   @if $type != 0 {
      // Число
      @return $result;
   }
   @return $result + 0em;
}
@mixin dynamic-option($option, $pc, $mobile, $stopMax: 320, $stopMin: 0, $before: null, $after: null, $viewportUnit: 100vw) {
   // Пропускные значения
   @if $stopMax == 0 {
      $stopMax: 320;
   }
   @if $before == 0 {
      $before: null;
   }
   @if $after == 0 {
      $after: null;
   }

   $addSize: $pc - $mobile;
   $designWidth: $designWidth - $stopMax;
   @if $stopMax > 320 {
      @include media-query($stopMax) {
         #{$option}: $before #{to-rem($mobile)} $after;
      }
   }
   @if $stopMin != 0 {
      @include media-query($stopMin, min) {
         #{$option}: $before #{to-rem($mobile + to-rem($addSize, 1) * math.div(($stopMin - $stopMax), to-rem($designWidth, 1)))} $after;
      }
   }
   #{$option}: $before calc(#{to-rem($mobile)} + #{to-rem($addSize, 1)} * (($viewportUnit - #{to-rem($stopMax)}) / #{to-rem($designWidth, 1)})) $after;
}
@mixin dynamic-font($pc, $mobile, $line-height: 0, $letter-spacing: 0, $stopMax: 320, $stopMin: 0, $before: null, $after: null, $viewportUnit: 100vw) {
   @include dynamic-option(font-size, $pc, $mobile, $stopMax, $stopMin, $before, $after, $viewportUnit);
   @if $line-height != 0 {
      line-height: #{prc($line-height, $pc, 1)};
   }
   @if $letter-spacing != 0 {
      letter-spacing: #{em($letter-spacing, $pc)};
   }
}
@mixin transition($duration: 0.3, $delay: 0, $timing-function: 0, $properties: null) {
   // Пропускные значения
   @if $duration == 0 {
      $duration: 0.3;
   }
   @if $delay == 0 {
      $delay: null;
   } @else {
      $delay: $delay + s;
   }
   @if $timing-function == 0 {
      $timing-function: null;
   }

   transition: $properties $duration + s $timing-function $delay;
}
