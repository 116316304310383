.quiz {
   position: relative;
   margin-top: calc(var(--header-height) * -1);
   padding-top: var(--header-height);
   color: #fff;
   &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      background: rgba(#0f0f0f, 0.85);
   }
   > img,
   > picture img,
   > video,
   > svg,
   > iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      z-index: 1;
   }
   &__swiper {
      position: relative;
      z-index: 3;
      .swiper-slide {
         .introduce,
         .step {
            min-height: calc((var(--vh) * 100) - var(--header-height));
         }
         &:nth-child(2) .step-navigation__btn_prev,
         .swiper-button-disabled {
            opacity: 0.5;
            pointer-events: none;
            img,
            svg {
               &:first-child {
                  opacity: 1;
               }
               &:last-child {
                  opacity: 0;
               }
            }
         }
      }
   }
}
