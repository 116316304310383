.graph-modal__close {
   background: none;
}
.graph-modal {
   &__container {
      padding: em(70) to-rem(24);
      max-width: to-rem(585);
      &_success {
         padding: em(40) to-rem(24);
      }
   }
   &__close {
      font-size: to-rem(24);
      @function em($one, $two: 24) {
         @return prc($one, $two, 1) + 0em;
      }
      right: em(25);
      top: em(25);
      width: 1em;
      height: 1em;
   }
   &__content {
   }
}
.popup {
   text-align: center;
   max-width: to-rem(500);
   margin: 0 auto;
   &__icon {
      font-size: em(40);
      @function em($one, $two: 40) {
         @return prc($one, $two, 1) + 0em;
      }
      margin-bottom: em(20);
      svg,
      img {
         width: 1em;
         flex-shrink: 0;
         height: 1em;
      }
   }
   &__title {
      color: rgb(0, 0, 0);
      font-size: em(32);
      @function em($one, $two: 32) {
         @return prc($one, $two, 1) + 0em;
      }
      font-weight: 700;
      line-height: em(39);
      margin-bottom: em(14);
      @include small-tablet {
         font-size: to-rem(24);
      }
      & + .btn {
         margin-top: em(25);
      }
   }
   &__sub-title {
      color: rgb(0, 0, 0);
      font-size: em(18);
      @function em($one, $two: 18) {
         @return prc($one, $two, 1) + 0em;
      }
      font-weight: 500;
      line-height: em(21);
      @include small-tablet {
         font-size: to-rem(16);
      }
      & + .btn {
         margin-top: em(25);
      }
   }
}
[data-graph-target="pop"] {
   padding: 0;
   background: none;
   border-radius: em(16);
   overflow: hidden;
   max-width: 90%;
   width: 100%;
   max-height: 90vh;
   video,
   iframe {
      position: relative;
      height: auto;
      width: 100%;
      aspect-ratio: 16 / 9;
   }
   .graph-modal {
      &__close {
         font-size: em(40);
         @function em($one, $two: 40) {
            @return prc($one, $two, 1) + 0em;
         }
         z-index: 10;
         top: em(8);
         right: em(8);
         background: rgba(#000, 0.8);
         border-radius: 50%;
         padding: em(12.5);
         width: em(40 + 10);
         height: em(40 + 10);
         img {
            max-width: 100%;
         }
      }
      &__content {
         padding: 0;
      }
   }
}

.graph-modal__container_thanks {
   font-size: 1rem;
   padding: em(37) em(24);
   max-width: to-rem(280);
   .graph-modal {
      &__close {
         font-size: em(16);
         @function em($one, $two: 24) {
            @return prc($one, $two, 1) + 0em;
         }
         right: em(19);
         top: em(19);
         width: 1em;
         height: 1em;
         img {
            max-width: 100%;
         }
      }
      &__content {
      }
   }
}
.thanks {
   display: flex;
   flex-direction: column;
   align-items: center;
   &__icon {
      font-size: em(80);
      @function em($one, $two: 80) {
         @return prc($one, $two, 1) + 0em;
      }
      width: 1em;
      height: 1em;
      margin-bottom: em(19);
      img {
         max-width: 100%;
      }
   }
   &__title {
      margin-bottom: em(8);
      color: #000000;
      font-size: em(14);
      @function em($one, $two: 14) {
         @return prc($one, $two, 1) + 0em;
      }
      line-height: 1.55;
      font-weight: 600;
   }
   &__sub-title {
      @function em($one, $two: 12) {
         @return prc($one, $two, 1) + 0em;
      }
      margin-bottom: em(17);
   }
   &__sub-title,
   &__link {
      color: #000000;
      font-size: em(12);
      @function em($one, $two: 12) {
         @return prc($one, $two, 1) + 0em;
      }
      line-height: 1.55;
   }
   &__socials {
      margin-bottom: em(19);
      display: flex;
      gap: em(10);
      img {
         font-size: em(30);
         @function em($one, $two: 30) {
            @return prc($one, $two, 1) + 0em;
         }
         width: 1em;
         height: 1em;
      }
   }
   &__socials + &__sub-title {
      margin-bottom: em(5);
   }
   &__link {
      text-decoration: underline;
      @media (any-hover: hover) {
         &:hover {
            text-decoration: none;
         }
      }
   }
}
