*,
*:before,
*:after {
   box-sizing: border-box;
}
.header__body,
.introduce__body,
.step__body,
.graph-modal__container_thanks {
   @include media-query(360, min) {
      zoom: 1.172;
   }
   @include media-query(380, min) {
      zoom: 1.2;
   }
   @include media-query(1600, min) {
      zoom: 1.333;
   }
   @include media-query(1750, min) {
      zoom: 1.458;
   }
   @include media-query(1900, min) {
      zoom: 1.6;
   }
}
.range__slider > .noUi-base {
   @include media-query(360, min) {
      zoom: 0.79;
   }
   @include media-query(380, min) {
      zoom: 0.8;
   }
   @include media-query(1600, min) {
      zoom: 0.74;
   }
   @include media-query(1750, min) {
      zoom: 0.68;
   }
   @include media-query(1900, min) {
      zoom: 0.62;
   }
}

.btn {
   font-size: 1rem;
   color: #000000;
   border-radius: em(10);
   min-height: em(50);
   display: inline-flex;
   justify-content: center;
   align-items: center;
   border: to-rem(3) solid $theme-1;
   background: $theme-1;
   padding: 0 em(20);
   @include mobile {
      width: 100%;
   }
   @include transition();
   @media (any-hover: hover) {
      &:hover {
         background: transparent;
         color: $theme-1;
      }
   }
   > span {
      font-size: em(14);
      @function em($one, $two: 14) {
         @return prc($one, $two, 1) + 0em;
      }
      line-height: 1.5;
      font-weight: 900;
      letter-spacing: em(1.5);
   }
}
.input {
   font-size: to-rem(17);
   @function em($one, $two: 17) {
      @return prc($one, $two, 1) + 0em;
   }
   border: to-rem(2) solid rgb(255, 255, 255);
   background-color: transparent;
   border-radius: em(10);
   min-height: to-rem(50);
   padding: 0 em(20);
   line-height: 1.33;
   width: 100%;
   outline: none;
   @include transition();
   color: #fff;
   &.just-validate-error-field {
      border-color: #ff8383;
   }
}
.radio,
.check {
   font-size: 1rem;
   padding-left: em(30);
   cursor: pointer;
   position: relative;
   min-height: em(20);
   &:before,
   &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1em;
      height: 1em;
   }
   &:before {
      font-size: em(20);
      @function em($one, $two: 20) {
         @return prc($one, $two, 1) + 0em;
      }
      border-color: rgba(251, 176, 60, 0.6);
      border-width: to-rem(2);
      border-style: solid;
   }
   &:after {
      transform: scale(0);
      @include transition();
   }
   &:has(input:checked) {
      &:after {
         transform: scale(1);
      }
      &:before {
         border-color: $theme-1;
      }
   }
   input {
      opacity: 0;
      width: 0;
      height: 0;
      font-size: 0;
   }
   span {
      font-size: em(14);
      @function em($one, $two: 14) {
         @return prc($one, $two, 1) + 0em;
      }
      font-weight: 700;
      line-height: em(20);
   }
}
.radio {
   &:before {
      border-radius: 50%;
   }
   &:after {
      font-size: em(10);
      @function em($one, $two: 10) {
         @return prc($one, $two, 1) + 0em;
      }
      top: em(5);
      left: em(5);
      border-radius: 50%;
      background: $theme-1;
   }
}
.check {
   &:after {
      font-size: em(15);
      @function em($one, $two: 15) {
         @return prc($one, $two, 1) + 0em;
      }
      top: em(2.5);
      left: em(2.5);
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fbb03c' viewBox='0 0 32 32'%3E%3Cpath d='M5 16.577l2.194-2.195 5.486 5.484L24.804 7.743 27 9.937l-14.32 14.32z'/%3E%3C/svg%3E");
   }
}
.range {
   font-size: 1rem;
   padding-top: em(46);
   &__slider {
      margin: 0 em(10);
      margin-bottom: em(20);
   }
   &__inputs {
      display: none;
   }
   &__limiters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
         font-size: em(10);
         @function em($one, $two: 10) {
            @return prc($one, $two, 1) + 0em;
         }
      }
   }
   .noUi-base {
      @include dynamic-font(16, 2);
      @include small-tablet {
         font-size: 1rem;
      }
   }
   .noUi-connect {
      background: #fff;
   }
   .noUi-horizontal {
      height: em(2);
   }
   .noUi-horizontal .noUi-handle {
      width: em(20);
      height: em(20);
      border-radius: 50%;
      background: $theme-1;
      cursor: pointer;
      right: em(-10);
      top: em(-10);
      box-shadow: none;
      border: none;
      outline: none;
      &:before,
      &:after {
         display: none;
      }
   }
   .noUi-tooltip {
      font-size: em(12);
      @function em($one, $two: 12) {
         @return prc($one, $two, 1) + 0em;
      }
      padding: em(8) em(10);
      min-width: em(40);
   }
}
.file {
   cursor: pointer;
   input {
      width: 0;
      height: 0;
      font-size: 0;
      opacity: 0;
   }
   > span {
      span {
         &:last-child {
            display: none;
         }
      }
   }
   &_uploaded {
      > span span {
         &:first-child {
            display: none;
         }
         &:last-child {
            display: inline-block;
         }
      }
   }
}
input[hidden] {
   display: none;
}
