html {
   box-sizing: border-box;
   scroll-behavior: smooth;
}

.page {
   height: 100%;
   font-family: var(--font-family, sans-serif);
   -webkit-text-size-adjust: 100%;
}

.page__body {
   margin: 0;
   min-width: to-rem(320);
   min-height: 100%;
   @include dynamic-font(16, 10, $stopMin: 1200);
}

.site-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   min-height: 100vh;
}
.main {
   flex: 1 1 auto;
}

a {
   text-decoration: none;
}

.is-hidden {
   display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
   border: none;
   padding: 0;
   background-color: transparent;
   cursor: pointer;
}

.list-reset {
   list-style: none;
   margin: 0;
   padding: 0;
}

.input-reset {
   -webkit-appearance: none;
   appearance: none;
   border: none;
   border-radius: 0;
   background-color: #fff;

   &::-webkit-search-decoration,
   &::-webkit-search-cancel-button,
   &::-webkit-search-results-button,
   &::-webkit-search-results-decoration {
      display: none;
   }
}

.visually-hidden {
   position: absolute;
   overflow: hidden;
   margin: -1px;
   border: 0;
   padding: 0;
   width: 1px;
   height: 1px;
   clip: rect(0 0 0 0);
}

.container {
   margin: 0 auto;
   padding: 0 var(--container-offset);
   max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
   outline: none;
}

.centered {
   text-align: center;
}

.dis-scroll {
   position: fixed;
   left: 0;
   top: 0;
   overflow: hidden;
   width: 100%;
   height: 100vh;
   overscroll-behavior: none;
}

.page--ios .dis-scroll {
   position: relative;
}

[id] {
   scroll-margin-top: em(100);
}

body.resize * {
   animation: none !important;
   transition: none !important;
}
